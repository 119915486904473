
import barba from '@barba/core';
import MicroModal from 'micromodal';

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){

        // MicroModal.show({
        //     openClass: 'is-open',
        //     disableFocus: false,
        //     awaitOpenAnimation: false,
        //     awaitCloseAnimation: false,
        //     debugMode: true
        // });
        const attatch = (t) => {
            const id = t.getAttribute("data-modal-trigger")
            t.addEventListener('click',(e)=>{
                e.preventDefault()
                MicroModal.show(id,
                    {
                        openClass: 'is-open',
                        disableFocus: false,
                        awaitOpenAnimation: false,
                        awaitCloseAnimation: false
                    }
                )
            })
        }
        const modalTrigger = document.querySelectorAll("[data-modal-trigger]")
        if(modalTrigger.length > 0){
            modalTrigger.forEach(t => {
                attatch(t)
            })
        }
    }
}