
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";


export default class{
    /**
     * loading要素の初期設定等を行うコンストラクタ
     * DOM生成時に即時実行
     */
    constructor() {
        this.mask = document.querySelector('#js-loading__mask')
        this.first = "#js-loading__mask .js-loading__first"
        this.logoAnimation_elm = document.querySelector('#logo-animation')
        this.logo_elm = document.querySelector('#js-loading__logo')
        if(this.mask){
            this.mask.style.display = "block"
        }
        if(process.env.MIX_DISP_LOADING == "false" && process.env.NODE_ENV == "development"){
            if(this.mask){
                this.mask.style.display = "none" 
            }
        }
    }
    /**
     * 初期表示前に1度だけ実行
     * @param {*} data 
     */
    beforeOnce(data){
        const self = this;
        return new Promise(resolve => {
            if(data.next.namespace == "not-loading"){
                resolve()
            }
            let timeline = gsap.timeline();
            const index = data.next.container.querySelector('#p-index')
            if(index){
                timeline
                .fromTo(
                    '#js-loading__logo',
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        delay:.4,
                        duration:.4,
                    }
                )
                timeline
                .fromTo(
                    '.js-loading__bg-lines',{
                        height: "0vh",
                    },{
                        height: "100vh",          
                        ease: "power3.inOut",
                        duration:.8,
                        onComplete :()=>{
                            setTimeout(() => {
                                this.initialScroll ()
                            }, 100);
                            resolve()
                        }
                    }
                )
            }else{
                setTimeout(() => {
                    this.page = document.querySelector("#page")
                    if(this.page){
                        this.page.style.opacity = "1"
                    }
                    setTimeout(() => {
                        this.initialScroll ()
                    }, 100);
                    resolve()
                }, 500);
            }
        })
    }
    /**
     * 初期表示時に1度だけ実行
     * @param {*} data 
     */
    once(data){
        return new Promise(resolve => {
            this.page = document.querySelector("#page")

            if(data.next.namespace == "not-loading"){
                if(this.page){
                    this.page.style.opacity = "1"
                }
                resolve();
            }

            const complete = () => {
                document.querySelector('[data-barba="container"]').removeAttribute('style')
                this.mask = document.querySelector('#js-loading__mask')
                if(this.mask){
                    this.mask.style.display = "none"
                }
                resolve();
            }


            const timelime = gsap.timeline()
            const index = data.next.container.querySelector('#p-index')
            if(index){ 
                timelime.fromTo(
                    ".js-loading__first",
                    {
                        y: 0
                    },
                    {
                        y: "-100%",
                        ease: 'power4.inOut',
                        duration : 2.5,
                        onStart :() =>{
                            gsap
                            .to(
                                '#js-loading__logo',
                                {
                                    opacity: 0,
                                    ease:"back.inOut(1.8)",
                                    duration : .4,
                                    delay:.4,
                                }
                            )
                            gsap.fromTo(
                                ".js-loading__first .m-curve-wrapper",
                                {
                                    scaleX : 1
                                },
                                {
                                    scaleX : 0,
                                    ease: 'expo.inOut',
                                    duration : 1,
                                }
                            )
                            gsap
                            .to(
                                '#page',
                                {
                                    opacity: 1,
                                    delay:.5,
                                    ease: 'power3.out',
                                    duration : 1,
                                }
                            )
                        },
                        onComplete : ()=>{
                            complete()
                        }
                    },'-=.7'
                )
            }else{
                timelime.fromTo(
                    ".js-loading__first",
                    {
                        y: 0
                    },
                    {
                        y : "100%",
                        ease: 'power4.inOut',
                        duration : 2.5,
                        onStart :() =>{
                            gsap.fromTo(
                                ".js-loading__first .m-curve-wrapper",
                                {
                                    scaleX : 1
                                },
                                {
                                    scaleX : 0,
                                    ease: 'expo.inOut',
                                    duration : 1,
                                }
                            )
                        },
                        onComplete : ()=>{
                            complete()
                        }
                    },'-=.7'
                )
            }            
        });
    }
    /**
     * 初期表示完了後に1度だけ実行
     * @param {*} data 
     */
    afterOnce(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み前に実行
     * @param {*} data 
     */
    beforeLeave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み時に実行
     * @param {*} data 
     */
    leave(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ読み込み完了後に実行
     * @param {*} data 
     */
    afterLeave(data){
        return new Promise(resolve => {
            this.mask = document.querySelector('#js-loading__mask')
            if(this.mask){
                this.mask.style.display = "block"
            }
            if(data.next.namespace == "not-loading"){
                resolve();
            }
            let wait = 0
            if( window.isDrawerLinkClicked ){
                window.isDrawerLinkClicked = false
                wait = 300
            }
            setTimeout(() => {
                gsap.set(data.next.container,{"opacity":0})
                gsap.set(data.current.container,{"width":"100vw","height":"100vh","position":"absolute","left":0,"top":0,"z-index":10})

                ScrollTrigger.matchMedia({
                    "(max-width: 1023px)": function() {
                        gsap.set(data.current.container.querySelector("[data-scroll-container]"),{"transform":"translateY(-"+ (window.scrollY) +"px)","width":"100vw","position":"absolute","left":0,"top":0})
                    }
                })
                gsap.set("#wrapper",{"perspective":"3000px","height":"100vh"})
                gsap.fromTo(
                    data.current.container,
                    {

                        filter:"blur(0px) brightness(1)",
                    },
                    {
                        opacity: 0,
                        scale: 1,
                        filter:"blur(15px) brightness(1.2)",
                        // x:"-20%",
                        // rotateY: ".5deg",
                        ease: 'power4.inOut',
                        duration : .8,
                        onComplete : () => {
                            gsap.set("#wrapper", { 
                                clearProps: "height",
                            });
                            data.current.container.style.display = "none"
                            resolve();
                        }
                    },
                )
                    
            }, wait);
        });
    }
    /**
     * 次ページ表示前に実行
     * @param {*} data 
     */
    beforeEnter(data){
        return new Promise(resolve => {
            resolve();
        })
    }
    /**
     * 次ページ表示時に実行
     * @param {*} data 
     */
    enter(data){
        return new Promise(resolve => {
            if(data.next.namespace == "not-loading"){
                data.next.container.style.opacity = 1;
                this.initialScroll ()
                resolve();
            }
            gsap.set(data.next.container,{"transformOrigin":"left center"})
            // gsap.set("#wrapper",{"perspective":"3000px"})
            const timelime = gsap.timeline()
            timelime
            .fromTo(
                data.next.container,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    ease: 'expo.inOut',
                    duration : 1,
                    // onStart: () => {
                    //     this.initialScroll ()
                    // },
                    onComplete: ()=>{
                        data.next.container.removeAttribute('style')
                        // gsap.set("#page",{"perspective":"0"})
                        // gsap.set("#wrapper", { 
                        //     clearProps: "perspective",
                        // });
                        this.mask = document.querySelector('#js-loading__mask')
                        if(this.mask){
                            this.mask.style.display = "none"
                        }    
                        resolve()
                    }
                },"-.3"
            )
        })
    }
    /**
     * 次ページ表示完了後に実行
     * @param {*} data 
     */
    afterEnter(data){
        return new Promise(resolve => {
            
            if(data.next.namespace == "not-loading"){
                data.next.container.style.opacity = 1;
                this.initialScroll ()
                resolve();
            }
            gsap.set(data.next.container,{"max-height":"100vh"})
            gsap.set("#wrapper",{"perspective":"3000px"})
            const timelime = gsap.timeline()
            timelime
            .fromTo(
                data.next.container,
                {
                    opacity: 0,
                    scale : 1.1,
                    filter:"blur(15px) brightness(1.2)",
                    // x:"20%",
                    // y:"40px",
                    // rotateY: "1deg",
                },
                {
                    opacity: 1,
                    scale : 1,
                    filter:"blur(0px) brightness(1)",
                    // x: 0,
                    // y: 0,
                    // rotateY: "0deg",
                    ease: 'expo.inOut',
                    duration : .8,
                    onStart: () => {
                        // setTimeout(() => {
                        // }, 400);
                    },
                    onComplete: ()=>{
                        data.next.container.removeAttribute('style')
                        // gsap.set("#page",{"perspective":"0"})
                        gsap.set("#wrapper", { 
                            clearProps: "perspective",
                        });
                        this.mask = document.querySelector('#js-loading__mask')
                        if(this.mask){
                            this.mask.style.display = "none"
                        } 
                        resolve()
                    }
                }
            )
        })
    }
    /**
     * すべて完了後に実行
     * @param {*} data 
     */
    after(data){
        return new Promise(resolve => {
            setTimeout(() => {
                
                this.initialScroll ()
            }, 100);
            resolve();
        })
    }
    initialScroll (){
        if( location.hash == "" ){
            window.scrollTo(0,0);
        }else{
            let digit = location.hash.replace(/^#/,"");
            let anchor = document.getElementById( digit );
            if ( !anchor ) return;
            
            if(anchor){
                const rect = anchor.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                let top = 0;
                const header = document.querySelector('#l-header');
                if(header){
                    top = (scrollTop + rect.top - header.clientHeight);
                }
                
                // if(window.innerWidth < 1024){
                // }else{
                //     window.LocomotiveScroll.scrollTo(anchor)
                // }
                // console.log( window.LocomotiveScroll)
                window.LocomotiveScroll.scrollTo(top,{duration: 500})

                    // window.scrollTo(0,top);
            }
        }
    }
}