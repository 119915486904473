
import barba from '@barba/core';
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay,EffectCreative } from 'swiper';
// configure Swiper to use modules
Swiper.use([Navigation, Pagination,Scrollbar, Autoplay,EffectCreative]);
import Splide from '@splidejs/splide';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.brandsSlider(data.next.container)
            this.indexBlogSlider(data.next.container)
            this.indexInterviewSlider(data.next.container)
            this.bloglistSlider(data.next.container)

        })
        barba.hooks.after((data) => {
            this.brandsSlider(data.next.container)
            this.indexBlogSlider(data.next.container)
            this.indexInterviewSlider(data.next.container)
            this.bloglistSlider(data.next.container)
        });
        
    }
    indexBlogSlider(container){
        const el = container.querySelector('.js-index-blog__slider')
        if(el){
            const indexBlog = new Swiper('.js-index-blog__slider', {
                easing:"linear",
                slidesPerView: 1.2,
                //spaceBetween: 24,
                simulateTouch:true,
                centeredSlides: false,
                // slidesPerView: 1.3,
                // freeMode: true,
                // scrollbar: {
                //     el: ".swiper-scrollbar",
                //     draggable: true,
                // },
                navigation: {
                    nextEl: '.js-index-blog-slider-next',
                    prevEl: '.js-index-blog-slider-prev',
                },
                spaceBetween: 40,
                breakpoints: {
                    576: {
                        slidesPerView: 1.2,
                    },
                    768: {
                        slidesPerView: 1.8,
                    },
                    992: {
                        slidesPerView: 2.4,
                    }
                }
            })
        }
    }
    brandsSlider(container){
        const el = container.querySelector('.js-brands__slider')
        if(el){
            const brands = new Swiper('.js-brands__slider', {
                easing:"linear",
                slidesPerView: 1.6,
                loop:true,
                //spaceBetween: 24,
                simulateTouch:true,
                centeredSlides: false,
                // slidesPerView: 1.3,
                // freeMode: true,
                navigation: {
                    nextEl: '.js-brands--gallery-slider-next',
                    prevEl: '.js-brands--gallery-slider-prev',
                },
                // scrollbar: {
                //     el: ".swiper-scrollbar",
                //     draggable: true,
                // },
                spaceBetween: 40,
                breakpoints: {
                    576: {
                        slidesPerView: 2.2,
                    },
                    768: {
                        slidesPerView: 2.8,
                    },
                    992: {
                        slidesPerView: 3.4,
                    }
                }
            })
        }
    }
    indexInterviewSlider(container){

        
        const el = container.querySelector('.js-index-interview__slider')
        if(el){

            const slide = new Splide('.js-index-interview__slider', {
                type   : 'loop',
                speed: 2000,
                gap : 40,
                fixedWidth: "24%", 
                focus: "center",
                pagination: false,
                drag:true,
                autoplay: true,
                interval : 4000,
                arrowPath: "M13.0991 20.2551L16.5082 23.8735C16.6306 24.0253 16.8458 24.043 16.9888 23.913C17.1318 23.783 17.1485 23.5546 17.026 23.4028C17.0146 23.3886 17.0021 23.3754 16.9888 23.3633L14.1627 20.36H27.6591C27.8474 20.36 28 20.198 28 19.9982C28 19.7983 27.8474 19.6363 27.6591 19.6363H14.1627L16.9888 16.6367C17.1318 16.5067 17.1485 16.2783 17.026 16.1265C16.9035 15.9747 16.6883 15.957 16.5453 16.087C16.532 16.0992 16.5196 16.1123 16.5082 16.1265L13.0991 19.7449C12.967 19.886 12.967 20.1139 13.0991 20.2551Z",
                classes: {
                    prev  : 'l-interview-slider-prev',
                    next  : 'l-interview-slider-next',
                },
                breakpoints: {
                    575: {
                        gap: 16,
                        fixedWidth: "72%",
                    },
                    767: {
                        gap: 24,
                        fixedWidth: "42%", 
                    },
                    991: {
                        gap: 32,
                        fixedWidth: "36%", 
                    },
                    1199: {
                        gap: 36,
                        fixedWidth: "28%", 
                    }
                }
            } ).mount();

            // const prev = el.querySelector('.l-interview-slider-prev')
            // const next = el.querySelector('.l-interview-slider-next')
            // const controls = el.querySelector('.p-index-interview__controls')
            // prev.classList.remove("splide__arrow")
            // next.classList.remove("splide__arrow")
            // controls.appendChild(prev)
            // controls.appendChild(next)
            
            slide.on( 'move', (newIndex,prevIndex,destIndex) => { 
                const activeSlide = el.querySelectorAll(".slide-active")
                if(activeSlide.length > 0){
                    activeSlide.forEach(slide =>{
                        slide.classList.remove('slide-active');
                    })
                }
                let nextSlide,cloneFirst ,cloneEnd
                if(newIndex > prevIndex){
                    
                    if(newIndex == slide.length - 1){
                        cloneEnd = el.querySelector('#'+slide.root.id+'-slide01').previousElementSibling
                        cloneEnd.classList.add('slide-active');
                        nextSlide = el.querySelector('#'+slide.root.id+'-slide0' + slide.length)
                    }else{
                        nextSlide = el.querySelector('#'+slide.root.id+'-slide0' + (newIndex + 1))
                    }
                    setTimeout(() => {
                        nextSlide.classList.add('slide-active');
                    }, 1);
                }
                if(newIndex < prevIndex){
                    if(newIndex == 0){
                        cloneEnd = el.querySelector('#'+slide.root.id+'-slide0' + (slide.length)).nextElementSibling
                        cloneEnd.classList.add('slide-active');
                        nextSlide = el.querySelector('#'+slide.root.id+'-slide01')
                    }else{
                        nextSlide = el.querySelector('#'+slide.root.id+'-slide0' + (newIndex + 1))
                    }
                    setTimeout(() => {
                        nextSlide.classList.add('slide-active');
                    }, 1);
                }
            } );
            
            // const indexInterview = new Swiper('.js-index-interview__slider', {
            //     easing:"linear",
            //     loop:true,
            //     slidesPerView: 1.3,
            //     simulateTouch: false,
            //     spaceBetween: 20,
            //     centeredSlides: true,
            //     speed:2000,
            //     noSwiping: true,
            //     autoplay: {
            //         delay: 4000,
            //         disableOnInteraction: false,
            //         easing: 'easeInOutQuad',
            //     },
            //     on: {
            //         // click: function() {
            //         //     this.slideTo(this.clickedIndex);
            //         // },
            //         slideChange: function() {
            //             let activeIndex = this.realIndex;
            //             if (this.loopedSlides && activeIndex >= this.loopedSlides) {
            //             activeIndex = activeIndex - this.loopedSlides;
            //             }
            //             const activeSlide = this.slides[activeIndex];
            //             this.slides.forEach(function(slide) {
            //             slide.classList.remove('swiper-slide-active');
            //             });
            //             activeSlide.classList.add('swiper-slide-active');
            //         }
            //     },
            //     // navigation: {
            //     //     nextEl: '.js-interview-slider-next',
            //     //     prevEl: '.js-interview-slider-prev',
            //     // },
            //     breakpoints: {
            //         576: {
            //             spaceBetween: 20,
            //             slidesPerView: 2,
            //         },
            //         768: {
            //             spaceBetween: 40,
            //             slidesPerView: 2.8,
            //         },
            //         992: {
            //             spaceBetween: 60,
            //             slidesPerView: 4.2,
            //         }
            //     }
            // })
            // const wrapper = container.querySelector('.js-index-interview__slider .swiper-wrapper')
            // const prev = container.querySelector('.js-index-interview__slider .js-interview-slider-prev')
            // const next = container.querySelector('.js-index-interview__slider .js-interview-slider-next')
            // prev.addEventListener('click',()=>{
               
            //     // indexInterview.slideToLoop(indexInterview.realIndex - 1)
            //     indexInterview.slidePrev(200)
            // })
            // next.addEventListener('click',()=>{
            //     // indexInterview.slideToLoop(indexInterview.realIndex + 1)
            //     indexInterview.slideNext(200)
            // })
        }
    }
    bloglistSlider(container){
        const el = container.querySelector('.js-bloglist-slider')
        if(el){
            const bloglist = new Swiper('.js-bloglist-slider', {
                speed:1000,
                slidesPerView: 'auto',
                spaceBetween: 20,
                simulateTouch:true,
                breakpoints: {
                    
                    576: {
                        slidesPerView: 'auto',
                        spaceBetween:  30,
                    },
                    992: {
                        slidesPerView: 'auto',
                        spaceBetween:  60,
                    }
                },
                navigation: {
                    nextEl: '.js-bloglist-slider-next',
                    prevEl: '.js-bloglist-slider-prev',
                },
            })
        }
    }
    
}