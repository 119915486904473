

import barba from '@barba/core';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

export default class{

	constructor(){
        this.isInit = false
        barba.hooks.once((data) => {
            // this.trigger = new ScrollTrigger()
            // this.initailTrigger()
            
            this.isInit = true
            this.init(data.next.container)

            const sections = document.querySelectorAll('[data-scroll-trigger]')
            if( sections.length > 0 ){
                sections.forEach( el => { 
                    el.classList.remove('-visible')
                })
            }
        });
        // barba.hooks.leave((data) => {
        //     this.kill(data)
        // });
        barba.hooks.afterEnter((data) => {
            if(data.trigger==='back' || data.trigger==='forward'){
                const sections = document.querySelectorAll('[data-scroll-trigger]')
                if( sections.length > 0 ){
                    sections.forEach( el => { 
                        el.classList.add('-visible')
                    })
                }
            }else{
                if(this.isInit){
                    this.init(data.next.container)
                }
            }

            // this.curve(data.next.container)
            // this.trigger = new ScrollTrigger()
            // this.initailTrigger()
        });

    }
    init(container){
        
        // ScrollTrigger.create({
        //     trigger: '.l-footer__contact--bg',
        //     scroller: "[data-scroll-container]",
        //     start: 'bottom bottom',
        //     end:'bottom top',
        //     pin: true,
        // });
        const strAnimations = document.querySelectorAll('[data-scroll-trigger="js-scroll-to-strs"]')
        if(strAnimations.length > 0 ){
            strAnimations.forEach((el) => {
                let _str = el.textContent.split("");
                let str = "";
                _str.map( s => {
                    str += "<span data-str-animation>" + s  + "</span>";
                })
                el.setAttribute("aria-label",el.textContent)
                el.innerHTML = str;
            })
        }
        let triggered = false

        const visible = (t) => {
            const triggerType = t.getAttribute('data-scroll-trigger')
            if(triggerType == 'js-scroll-to-strs'){

                if(!triggered){
                    const strs = t.querySelectorAll('[data-str-animation]');

                    gsap.fromTo(strs,
                        {
                            y: "100%",
                        },
                        {
                            y: "0%",
                            ease: "power4.easeOut",
                            stagger: { 
                                each:.024,
                            },
                            onComplete(){
                                t.classList.add('-visible')
                                triggered = true
                            }
                        }
                    );
                }
            }else{
                if(!t.classList.contains('-visible')){
                    t.classList.add('-visible')
                }
            }

        }
        const callback = (entries) => {
            entries.forEach( (entry) => {
                
                if (entry.isIntersecting) {
                    visible(entry.target);
                }
            });
        }
        const observerOptions = {
            rootMargin: "0px 0px -20% 0px",
            threshold: 0
        }
        this.observer = new IntersectionObserver(callback, observerOptions)
        const sections = container.querySelectorAll('[data-scroll-trigger]')
        if( sections.length > 0 ){
            sections.forEach( el => { 
                this.observer.observe(el)
            })
        }
    }

    // curve(container){
    //     const els = container.querySelectorAll(".c-curve")
    //     if (els.length > 0) {
    //         els.forEach(el => {
    //             gsap.to(
    //                 el,
    //                 {
    //                     height:"0px",
    //                     scrollTrigger: {
    //                         trigger: el.parentElement,
    //                         scroller: "[data-scroll-container]",
    //                         start: 'top center',
    //                         end: 'bottom top',
    //                         scrub:true,
    //                         // pin:el
    //                     }
    //                 }
    //             );        
    //         })
    //     }
    // }

}