
import barba from '@barba/core';
import gsap from "gsap";

import Swiper, {  Autoplay, EffectFade, EffectCreative } from 'swiper';

import 'swiper/swiper-bundle.min.css'

Swiper.use([ Autoplay, EffectFade, EffectCreative]);

export default class{

	constructor(){
        
        barba.hooks.once((data) => {
            const index = data.next.container.querySelector("#p-index")
            if(index){
                this.intro(true,data.next.container)
            }
        })
        barba.hooks.beforeLeave((data) => {
            const index = data.current.container.querySelector("#p-index")
            if(index){
                this.tl1.kill()
                this.tl2.kill()
                this.tl3.kill()
                this.tl4.kill()
            }
        })
        // barba.hooks.enter((data) => {
        //     const index = data.next.container.querySelector("#p-index")
        //     if(index){
        //         gsap.set("#p-index-hero__image",
        //         {
        //             scale: 1.1,
        //             y: "10%",
        //             opacity:0
        //         })
        //     }
        // })
        barba.hooks.after((data) => {
            const index = data.next.container.querySelector("#p-index")
            if(index){
                this.intro(false,data.next.container)
            }
        })
    }
    

    intro(isOnce,container){
        let wait = 1
        
        // const timelime = anime.timeline()
        
        if(isOnce){
            wait = 2
        }
        new Swiper ('.p-index-hero__slider',{
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed:2000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 3000,
            },
        })
        this.tl1 = gsap.timeline({delay:wait})
        const first = container.querySelectorAll('[data-animation-first-el] [data-animation-str]')
        
        // this.tl1.set("#p-index-hero__catch--image__line1 path",{strokeDashoffset : 320})
        // this.tl1.set(".p-index-hero__scroller",{
        //     opacity:0,
        //     y: 20    
        // })
        
        // gsap.set("#p-index-hero__catch--image__line2 path",{strokeDashoffset : 300})

        // if(isOnce){
        //     this.tl1.set("#p-index-hero__image",{
        //         y: "-50vh",
        //         width:"100vw",
        //         height:"100vh",
        //         scale:1.2,
        //         borderRadius:0,
        //     })
        //     this.tl1.from("#p-index-hero__image",
        //         {
        //             y: "-50vh",
        //             width:"100vw",
        //             height:"100vh",
        //             scale:1.2,
        //             borderRadius:0,
        //             delay:.8,
        //             ease: "expo.inOut",
        //             duration:1.2,
        //             onComplete: function () {
        //                 gsap.set("#p-index-hero__image", { 
        //                     clearProps: "all",
                            
        //              });
        //             }
        //     })
        // }


        // this.tl1.to(".p-index-hero__catch--en",
        // {
        //     opacity:1,
        //     duration:1,
        //     ease: "power1",
        // }, "-=.5")


        // this.tl1.fromTo(first,
        // {
        //     y: "100%",
        // },
        // {
        //     y: "0%",
        //     ease: "power3.out",
        //     duration:.8,
        //     stagger: { 
        //         each:.024,
        //     }
        // }, "-=1")

        this.tl1.fromTo(".p-index-hero__catch--ja",{
            y: "20px",
            opacity:0
        },
        {
            y: "0%",
            opacity:1,
            duration:1.6,
            ease: "power2"
        }, "-=.8")
        this.tl1.fromTo("#p-index-hero__image",{
            y: "40px",
            opacity:0,
        },
        {
            y: "0%",
            opacity:1,
            duration:1.6,
            ease: "power2"
        }, "-=1.6")

        this.tl1.fromTo(".p-index-hero__scroller",{
            y: "20px",
            opacity:0,
        },
        {
            y: "0%",
            opacity:1,
            duration:1.6,
            ease: "power2"
        }, "-=1.6")
       
        // this.tl1.fromTo(
        // "#p-index-hero__catch--image__line1 path",
        // {
        //     strokeDashoffset : 320,
        // },
        // {
        //     ease: "power3.out",
        //     strokeDashoffset : 0,
        //     duration:.7,
        //     stagger: { 
        //         each:.08,
        //     }
        // }, "-=.8")
        
        // this.tl1.fromTo(
        // "#p-index-hero__catch--image__line1 path",
        // {
        //     strokeDashoffset : 0,
        // },
        // {
        //     ease: "power3.out",
        //     strokeDashoffset : 320,
        //     duration:.7,
        //     stagger: { 
        //         from:"end",
        //         each:.08,
        //     },
        //     onComplete:() =>{
        //         this.loop(container)
        //     }
        // },"+=4")
        
    }
    loop(container){
        let state = 2
        this.tl2 = gsap.timeline({repeat:-1, repeatDelay: 5})
        this.tl3 = gsap.timeline({paused:true})
        this.tl4 = gsap.timeline({paused:true})
        const first = container.querySelectorAll('[data-animation-first-el] [data-animation-str]')
        const second = container.querySelectorAll('[data-animation-second-el] [data-animation-str]')

        this.tl3.fromTo(
            "#p-index-hero__catch--image__line1 path",
            {
                strokeDashoffset : 0,
            },
            {
                ease: "power3.out",
                strokeDashoffset : 320,
                duration:.7,
                stagger: { 
                    from:"end",
                    each:.08,
                }
            }
        )
        this.tl3.fromTo(
            "#p-index-hero__catch--image__line1 path",
            {
                strokeDashoffset : 320,
            },
            {
                ease: "power3.out",
                strokeDashoffset : 0,
                duration:.7,
                stagger: { 
                    each:.12,
                }
            }
            , "-=5"
        )
        this.tl4.fromTo(
            "#p-index-hero__catch--image__line2 path",
            {
                strokeDashoffset : 0,
            },
            {
                ease: "power3.out",
                strokeDashoffset : 300,
                duration:.7,
                stagger: { 
                    from:"end",
                    each:.12,
                }
            }
        )
        this.tl4.fromTo(
            "#p-index-hero__catch--image__line2 path",
            {
                strokeDashoffset : 300,
            },
            {
                ease: "power3.out",
                strokeDashoffset : 0,
                duration:.7,
                stagger: { 
                    each:.12,
                }
            }
            , "-=5"
        )


        this.tl2.fromTo(first,
            {
                y: "0%",
            },
            {
                y: "-100%",
                ease: "power3.out",
                duration:.8,
                stagger: { 
                    each:.015,
                }
            }
        );
        this.tl2.fromTo(second,
            {
                y: "0%",
            },
            {
                y: "-100%",
                ease: "power3.out",
                duration:.8,
                stagger: { 
                    each:.024,
                },
                onComplete :() =>{

                    if(state == 1) {
                        this.tl3.play(0)
                        state = 2
                    }else{
                        this.tl4.play(0)
                        state = 1
                    }
                }
            }, "-=.7"
        );
        
    }   
}