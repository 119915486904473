import barba from '@barba/core';
import gsap from "gsap";

export default class{
    constructor(){
        
        this.targetEL = {}
        this.attraction = 10;
        this.mouse = {
            x: 0,
            y: 0,
            cx: 0,
            cy: 0
        }
        this.el = {};
        this.frag = false;
        barba.hooks.once(() => {
            this.targets = document.querySelectorAll('[data-mouse-sticky]')
            if(this.targets.length > 0){
    
                this.init()
            }
        });
        barba.hooks.after(() => {
            this.targets = document.querySelectorAll('[data-mouse-sticky]')
            if(this.targets.length > 0){
                this.init()
            }
        });
    }
    
    init(){
        this.targets.forEach((target,index) =>{
            this.handle(target,index)
        })


        this.bindMouseMove = this.mouseMove.bind(this);
        this.onRequestAnimationFrame();
    }

    handle(target,index){
        
        target.addEventListener('mouseenter', () => { 
            this.targetEL = target;
            this.gbcr = target.getBoundingClientRect();
            
            const target_item = target.querySelector('[data-mouse-sticky-target]')
            const rotation = target.getAttribute('data-mouse-sticky-rotation')
            const distance = target.getAttribute('data-mouse-sticky-distance')
            this.el = {
                target: target,
                target_item: (target_item) ? target_item: target,
                distance: (distance) ? distance : this.attraction,
                rotation: (rotation) ? rotation : 1,
                x: this.gbcr.x,
                y: this.gbcr.y,
                w: this.gbcr.width,
                h: this.gbcr.height
            }
            this.mouseEnter()
        });
        target.addEventListener('mouseleave', () => {
            this.mouseLeave()
        });
    }
    mouseMove(e) {
        this.gbcr = this.el.target.getBoundingClientRect();
        this.mouse.x = gsap.utils.mapRange(this.gbcr.x, this.gbcr.x + this.gbcr.width, -this.el.distance, this.el.distance, e.clientX);
        this.mouse.y = gsap.utils.mapRange(this.gbcr.y , this.gbcr.y + this.gbcr.height , -this.el.distance, this.el.distance, e.clientY);
        
    }

    mouseEnter() {
        this.frag = true;
        addEventListener('mousemove',this.bindMouseMove);
    }

    mouseLeave() {
        this.mouse.cx = 0;
        this.mouse.cy = 0;
        gsap.to(this.el.target_item, {
            x: 0,
            y: 0,
            duration: .8,
            rotateY: '0deg',
            rotateX: '0deg',
            ease: 'back.out'
        });
        removeEventListener('mousemove',this.bindMouseMove);
        this.targetEL = false;
        this.el = {}
        this.frag = false;
    }

    onRequestAnimationFrame() {
        if(this.frag){
            this.mouse.cx = gsap.utils.interpolate(this.mouse.cx, this.mouse.x, 0.1);
            this.mouse.cy = gsap.utils.interpolate(this.mouse.cy, this.mouse.y, 0.1);
            gsap.set(this.el.target, {
                perspective: "3000px"
            })
            gsap.set(this.el.target_item, {
                x: this.mouse.cx,
                y: this.mouse.cy,
                rotateY: (this.mouse.cx / this.el.rotation) + 'deg',
                rotateX: (this.mouse.cy / -this.el.rotation) + 'deg',
                transformOrigin:(this.mouse.cx > 0) ? "right center" : "left center",
            });
        }
        
        requestAnimationFrame(() => { this.onRequestAnimationFrame() });
    }
}
