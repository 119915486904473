'use strict'

import '@babel/polyfill'

// import lazysizes from "lazysizes"
// import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
require('intersection-observer');

import ReplaceHead from "./lib/ReplaceHead"
import GA from "./lib/GA"

// import ViewportUnitsBuggyfill from "./lib/ViewportUnitsBuggyfill"
// import IeFix from "./lib/IeFix"
import Svg from "./lib/Svg"
import Resources from "./lib/Resources"
import Share from "./lib/Share"
import NewWindow from "./lib/NewWindow"
import Datepicker from "./lib/Datepicker"
import Drawer from "./lib/Drawer"
import Form from "./lib/Form"
import Lightbox from "./lib/Lightbox"
import Company from "./lib/Company"
import ScrollTrigger from "./lib/ScrollTrigger"
import SmoothScroll from "./lib/SmoothScroll"
import Slider from "./lib/Slider"

import MouseSticky from "./lib/MouseSticky"
import LocomotiveScroll from "./lib/LocomotiveScroll";

// import Tab from "./lib/Tab"
// import ObjectFitImages from "./lib/ObjectFitImages"
// import Accordion from "./lib/Accordion"
import Barba from "./lib/Barba"
import MouseStalker from "./lib/MouseStalker"
import Top from "./lib/Top"
import LinkHoverAnimation from "./lib/LinkHoverAnimation"
import Modal from "./lib/Modal"



const eventHandler = () => {
    // new ViewportUnitsBuggyfill
    new Resources
    // new IeFix
    new Barba
    new Svg
    new Form
    new Datepicker
    new GA
    new ReplaceHead
    new Share
    new NewWindow

    new LocomotiveScroll
    new MouseStalker
    new Slider
    new Drawer
    new MouseSticky
    new Lightbox

    // new Accordion
    // new Tab
    new ScrollTrigger
    new Company
    new SmoothScroll
    new Top
    new LinkHoverAnimation
    new Modal

    const logger = console.log
        console.log = (...args) => {
          if (args[0] !== 'THREE.WebGLRenderer') {
            logger(...args)
          }
    }
}

if (document.readyState !== 'loading') {
    eventHandler();
}else{
    document.addEventListener('DOMContentLoaded', eventHandler, false);
}


