

import barba from '@barba/core';
import gsap from "gsap";

export default class{

	constructor(){

        barba.hooks.once((data) => {
            this.run(document)
        })
        barba.hooks.enter((data) => {
            this.run(data.next.container)
        });
    }
    
    run(container){
        const animation_links = container.querySelectorAll('[data-hover-animation]')
        if(animation_links.length > 0){
            animation_links.forEach(link => {
                const str = link.querySelector('[data-hover-animation-target]')
                if(str){
                    if(link.getAttribute('aria-label')==null){
                        link.setAttribute('aria-label',str.textContent)
                    }
                    let html = ""
                    const strArray = str.textContent.split('')
                    strArray.forEach(s => {
                        html = html +'<span data-hover-animation-str>' + s + '</span>'
                    })
                    const firstEl = document.createElement('span')
                    firstEl.setAttribute('data-hover-animation-first-el',"")
                    firstEl.setAttribute('aria-hidden',"true")
                    firstEl.innerHTML = html
                    str.innerHTML = ""
                    str.appendChild(firstEl);

                    const secondEl = document.createElement('span')
                    secondEl.setAttribute('data-hover-animation-second-el',"")
                    secondEl.setAttribute('aria-hidden',"true")
                    secondEl.innerHTML = html
                    str.appendChild(secondEl);

                    link.addEventListener("mouseenter",()=>{
                        this.hovanimation(link)
                    })
                    link.addEventListener("touchstart",()=>{
                        this.hovanimation(link)
                    })
                }
            })
        }
        // const buttons = document.querySelectorAll('.c-button')
        // if(buttons.length > 0){
        //     buttons.forEach(btn => {
        //         btn.querySelector('.data-')
        //         btn.addEventListener("mouseenter",()=>{
                    
        //         })
        //     })
        // }

        // const btn_links = document.querySelectorAll('[data-btn-links]')
        // if(btn_links.length > 0){
        //     btn_links.forEach(link => {
        //         const str = link.querySelector('[data-btn-links-str]')
        //         link.setAttribute('data-str',str.innerText)
        //     })
        // }

        // const drawer_links = document.querySelectorAll('[data-drawer-links]')
        // if(drawer_links.length > 0){
        //     drawer_links.forEach(link => {
        //         const str = link.querySelector('[data-drawer-links-str]')
        //         str.setAttribute('data-str',str.innerText)

        //         const str_en = link.querySelector('[data-drawer-links-str-en]')
        //         str_en.setAttribute('data-str-en',str_en.innerText)
        //     })
        // }
    }

    hovanimation(link){
        const first = link.querySelectorAll('[data-hover-animation-first-el] [data-hover-animation-str]')
        gsap.fromTo(first,
            {
                y: "0%",
            },
            {
                y: "-100%",
                ease: "power4.easeOut",
                stagger: { 
                    each:.015,
                }
            }
        );
        const second = link.querySelectorAll('[data-hover-animation-second-el] [data-hover-animation-str]')
        gsap.fromTo(second,
            {
                y: "0%",
            },
            {
                y: "-100%",
                ease: "power4.easeInOut",
                stagger: { 
                    each:.024,
                }
            }
        );
    }

}