
import barba from '@barba/core';
import MicroModal from 'micromodal';
import gsap from "gsap";

export default class{

	constructor(){
        this.drawerLinks = []
        this.opened = false
        this.isDrawerLinkClicked = false
        this.opening_class = 'drawer-opening'
        this.opened_class = 'drawer-opened'
        this.closing_class = 'drawer-closing'
        
        this.header_id = "l-header"
        this.hamburger_id = "js-hamburger"
        this.drawer_id = "js-drawer"
        this.drawer_link_class = "[data-drawer-links]"
        this.drawer_items = "[data-drawer]"
        this.drawer_str_class = "l-drawer__nav--str"

        barba.hooks.once((data) => {
            this.init()
        })
     
    }

    init(){
        this.body = document.body;
        this.header =  document.querySelector('#' +this.header_id);
        this.hamburger = document.querySelector('#' +this.hamburger_id);
        this.drawer = document.querySelector('#' + this.drawer_id);
        if(this.drawer){
            this.drawerLinks = this.drawer.querySelectorAll(this.drawer_link_class);
            if(this.hamburger){
                this.hamburger.addEventListener('click',(e) => {
                    this.drawerClick(e);
                })
            }
            if(this.drawerLinks){
                if( this.drawerLinks.length > 0 ){
                    this.drawerLinks.forEach( (drawerLink) => {
                        this.drawerRemoveClass(drawerLink)
                    });
                }
            }
            this.nowImage = ""
            this.drawerHoverItems = this.drawer.querySelectorAll(this.drawer_items);
            if( this.drawerHoverItems.length > 0 ){
                this.drawerHoverItems.forEach( ( el ) => {
                    el.addEventListener('mouseenter',() =>{
                        const name = el.getAttribute('data-drawer')
                        const image = document.querySelector('[data-drawer-image="'+ name+'"]')
                        const images = document.querySelectorAll('[data-drawer-image]')
                        if(images.length > 0){
                            images.forEach(i =>{
                                i.classList.remove('-active')
                            })
                        }
                        if(image){
                            // if(this.nowImage != name){
                                image.classList.add('-active')
                                this.nowImage = name
                            // }
                        }
                    })
                });
            }
        }
    }
    drawerClick(e){
        this.drawerToggleClass();
    }
    drawerToggleClass(){
        if(!this.drawer.classList.contains('is-open')){
            this.header.classList.add('drawer-open');
            this.open();
        }else{
            this.header.classList.remove('drawer-open');
            this.close();
        }
    }
    drawerRemoveClass(drawerLink){
        drawerLink.addEventListener('click',() =>{
            this.isDrawerLinkClicked = true
            window.isDrawerLinkClicked = true
            this.header.classList.remove('drawer-open');
            this.close();
        })
    } 
    open(){



        MicroModal.show(this.drawer_id,{
            onShow (){
                document.querySelector("#js-drawer .modal__container").scrollTop = 0
                window.LocomotiveScroll.stop()
                gsap.to(
                    '[data-barba="container"]',
                    {
                        y:"10px",
                        ease: 'expo.out',
                        duration: 1,
                        onComplete(){

                            document.querySelector('[data-barba="container"]').removeAttribute('style')
                        }
                    }
                )
            }, // [1]
            onClose: () => {
                window.LocomotiveScroll.start()

                document.querySelector('#js-hamburger').setAttribute('aria-expanded',false)
                if(!this.isDrawerLinkClicked){
                    gsap.fromTo(
                        '[data-barba="container"]',
                        {
                            y:"10px"
                        },
                        {
                            y:0,
                            ease: 'expo.out',
                            duration: 1,
                            onComplete(){

                                document.querySelector('[data-barba="container"]').removeAttribute('style')
                                window.isDrawerLinkClicked = false
                            }
                        }
                    )
                }
                this.isDrawerLinkClicked = false
            }, 
            // openTrigger: 'data-custom-open', // [3]
            // closeTrigger: 'data-custom-close', // [4]
            // openClass: 'is-open', // [5]
            disableScroll: true, // [6]
            disableFocus: true, // [7]
            awaitOpenAnimation: true, // [8]
            awaitCloseAnimation: true, // [9]
        })
        this.hamburger.setAttribute('aria-expanded',true)
        // anime({
        //     targets:this.drawer_str_class,
        //     translateY:['100%',0],
        //     easing: 'easeInOutSine',
        //     duration:500,
        //     delay:function(el,i){
        //         return i * 30;
        //     }
        // })
        // this.opened = true;

    }
    close (){
        // this.body.classList.remove(this.opening_class);
        // this.body.classList.add(this.closing_class);
        this.hamburger.setAttribute('aria-expanded',false)
        // setTimeout(() =>{
        //     this.drawer.scrollTop =  0;
        //     this.body.classList.remove(this.closing_class);
        //     this.body.classList.remove(this.opened_class);
        //     this.body.classList.remove(this.opening_class);
        //     this.opened = false;
        // },300)
        MicroModal.close(this.drawer_id)
    }
}