
import barba from '@barba/core';
import MicroModal from 'micromodal';

export default class{

	constructor(){
        
        barba.hooks.afterOnce((data) => {
            const company = data.next.container.querySelector("#p-company")
            if(company){
                this.init(data.next.container)
            }
        })

        barba.hooks.after((data) => {
            const company = data.next.container.querySelector("#p-company")
            if(company){
                this.init(data.next.container)
            }
        });

    }
    init (container){
        const triggers = container.querySelectorAll("[data-staff-model-trigger]")
        if(triggers.length > 0){
            triggers.forEach(el => {
                el.addEventListener("click",(event)=>{
                    event.preventDefault()
                    this.open(el)
                })
            });
        }
    }
    open(el){
        const container = document.querySelector('#js-staff-modal-content')
        MicroModal.show("js-staff-modal",{
            onShow:()=>{
                const id = el.getAttribute('data-staff-model-trigger') 
                const content = document.querySelector( id)
                if(content){
                    container.innerHTML = content.innerHTML
                }
            },
            onClose:()=>{
                container.innerHTML = ""
            },
            disableScroll: true,
            disableFocus: true,
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
        })
    }
    close (){
        MicroModal.close("js-staff-modal")
    }
}