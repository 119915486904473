

import barba from '@barba/core';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import LocomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){

        this.isInit = false
		this.initY = 0
        this.triggerIndexFeatures = false
        this.triggerIndexProductPrimary = false
        this.triggerIndexProductSecondary = false

        window.historyScrollPosition = []
        
        barba.hooks.once((data) => {
            this.run(document.querySelector("[data-scroll-container]"))
            this.refresh()
            this.switch_theme(data.next.container)
        })

        barba.hooks.beforeLeave((data) => {
            if(data.trigger == "back" || data.trigger == "forward"){
                if(window.historyScrollPosition.length > 0){
                    this.initY = window.historyScrollPosition.pop()
                }
            }
            window.historyScrollPosition.push(window.LocomotiveScroll.scroll.instance.scroll.y)
        })

        barba.hooks.afterLeave((data) => {
            ScrollTrigger.killAll();
            window.LocomotiveScroll.destroy()
        })
        
        

        barba.hooks.afterEnter((data) => {
            if(this.isInit){   
                // gsap.set("html",{"overflow":"hidden"})  
                this.switch_theme(data.next.container)
            }
            if(!this.isInit){
                this.isInit = true
            }
        })

        barba.hooks.after((data) => {
            if(data.trigger==='back' || data.trigger==='forward'){
                const sections = document.querySelectorAll('[data-scroll-trigger]')
                if( sections.length > 0 ){
                    sections.forEach( el => { 
                        el.classList.add('-visible')
                    })
                }
                setTimeout(() => {
                    ScrollTrigger.refresh();
                }, 100);
            }else{                  
                this.initY = 0
            }
               
            setTimeout(() => {
                this.run(data.next.container.querySelector("[data-scroll-container]"))
                ScrollTrigger.refresh(); 
            }, 100);    
        });
    }
    run(el){

        const header = document.querySelector('#l-header');
        // Using Locomotive Scroll from Locomotive https://github.com/locomotivemtl/locomotive-scroll
        window.LocomotiveScroll = new LocomotiveScroll({
            el: el,
            smooth: true,
            lerp:.4,
            multiplier:.5,
            initPosition :{
                x: 0,
                y: this.initY
            },
        });
        
        // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
        window.LocomotiveScroll.on("scroll", ScrollTrigger.update);

        // tell ScrollTrigger to use these proxy methods for the "[data-scroll-container]" element since Locomotive Scroll is hijacking things
        ScrollTrigger.scrollerProxy("[data-scroll-container]", {
            scrollTop(value) {
                return  window.LocomotiveScroll.scroll.instance.scroll.y;
            }, // we don't have to define a scrollLeft because we're only scrolling vertically.
            getBoundingClientRect() {
                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
            },
            // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
            pinType: document.querySelector("[data-scroll-container]").style.transform ? "transform" : "fixed"
        });

        const hero_image = el.querySelector("#p-index-hero__image")
        if (hero_image){
            gsap.to(
                "#p-index-hero__image",
                {
                    scrollTrigger: {
                        trigger: "#p-index-hero__image",
                        endTrigger:"#p-index-philosophy",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'bottom bottom',
                        scrub:true,   
                        pin:true, 
                        pinSpacing:false,                          
                    }
                }
            );
            gsap.fromTo(
                "#p-index-hero__image",
                {
                    scale: "1",
                },
                {
                    scale:"1.2",
                    scrollTrigger: {
                        trigger: "#p-index-hero__image",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'center top',
                        scrub:true,                      
                    }
                }
            );
            gsap.fromTo(
                "#p-index-hero__image .p-index-hero__image--hider",
                {
                    opacity: "0",
                },
                {
                    opacity: ".8",
                    scrollTrigger: {
                        trigger: "#p-index-hero__image",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'center top',
                        scrub:true,
                    }
                }
            );
            

        }
        const index_philosophy = el.querySelector("#p-index-philosophy")
        if (index_philosophy){
            gsap.to(":root", {
                "--light-to-dark": "#0A131C",
                "--dark-to-light": "#F5ECE3",
                "--light-to-dark-rgb": "10, 19, 28",
                "--dark-to-light-rgb": "245, 236, 227",
                duration: .4,
                scrollTrigger: {
                    trigger: "#p-index-philosophy",
                    endTrigger:"#p-index-brands",
                    scroller: "[data-scroll-container]",
                    start: "center center",
                    end: "bottom center+=30%",
                    toggleActions: "play reverse play reverse",
                    // markers: true
                },
            })
        }
        const index_brands = el.querySelector("#p-index-brands")
        if (index_brands){
            const tl1 = gsap.timeline({
                scrollTrigger: {
                    trigger: "#p-index-brands",
                    scroller: "[data-scroll-container]",
                    start: "center center+=40%",
                    end: "center top",
                }
            })
            tl1.fromTo(".circle",{
                autoAlpha: 0,
                'stroke-dashoffset': '3780px',
                'stroke-opacity': 1,
                scale: .8,
                rotate: "-90deg",
                'transform-origin': 'center',
            },
            {
                autoAlpha: 1,
                'stroke-dashoffset': '0px',
                'stroke-opacity': 0.1,
                scale: 1,
                ease: "quart.out",
                duration: 2.4,
            })
            .fromTo(".p-index-brands__logo",{
                autoAlpha: 0,
                scale: .8,
                'transform-origin': 'center',
            },
            {
                autoAlpha: 1,
                scale: 1,
                ease: "quart.out",
                duration: 1.6,
            },"-=1.6")
            .fromTo(".p-index-brands__images",{
                autoAlpha: 0,
                scale: .95,
                'transform-origin': 'center',
            },
            {
                autoAlpha: 1,
                scale: 1,
                ease: "quart.out",
                duration: 1.6,
            },"-=.8");
        }   
        const index_recruitIntro = el.querySelector('#p-index-recruit__intro')
        if (index_recruitIntro){
            gsap.to(
                "#p-index-recruit__intro",
                {
                    scrollTrigger: {
                        trigger: "#p-index-recruit__intro",
                        endTrigger: ".p-index-message__wrapper",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'top bottom',
                        scrub:true,   
                        pin:true, 
                        pinSpacing:false,   
                        // markers:true                        
                    }
                }
            );
            ScrollTrigger.matchMedia({
                "(max-width: 767px)": function() {
                  gsap.to(
                    ".p-index-recruit__intro--bg",
                    {
                      width: "300vw",
                      height: "300vw",
                      scrollTrigger: {
                        trigger: "#p-index-recruit__intro",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'bottom top',
                        scrub:true,              
                      }
                    }
                  );
                },
                "(min-width: 768px)": function() {
                  gsap.to(
                    ".p-index-recruit__intro--bg",
                    {
                      width: "200vw",
                      height: "200vw",
                      scrollTrigger: {
                        trigger: "#p-index-recruit__intro",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'bottom top',
                        scrub:true,                     
                      }
                    }
                  );
                }
            })              
            gsap.fromTo(
                ".p-index-recruit__intro--str",
                {
                    opacity:"0",
                    scale:"0",
                },
                {
                    opacity:"1",
                    scale:"1",
                    scrollTrigger: {
                        trigger: "#p-index-recruit__intro",
                        scroller: "[data-scroll-container]",
                        start: 'center center',
                        end: 'center top-+=20%',
                        scrub:true,
                        // markers:true                         
                    }
                }
            );
            gsap.fromTo(
                ".p-index-recruit__intro--bar",
                {
                    height: "0px",
                },
                {
                    height: "300px",
                    scrollTrigger: {
                        trigger: "#p-index-recruit__intro",
                        scroller: "[data-scroll-container]",
                        start: 'center center-=10%',
                        end: 'bottom top',
                        scrub:true,                      
                    }
                }
            );
            gsap.fromTo(
                ".p-index-recruit__intro--point",
                {
                    opacity:"0",
                },
                {
                    opacity:"1",
                    scrollTrigger: {
                        trigger: "#p-index-recruit__intro",
                        scroller: "[data-scroll-container]",
                        start: 'center center-=10%',
                        end:  'center center-=20%',
                        scrub:true,                      
                    }
                }
            );
            gsap.to(
                "#p-index-recruit__intro .bg-lines",
                {
                    opacity:0,
                    scrollTrigger: {
                        trigger: ".p-index-message__wrapper",
                        scroller: "[data-scroll-container]",
                        start: 'center+=20% bottom',  
                        end: 'center+=20% bottom-=20%', 
                        scrub:true,                   
                    }
                }
            );
            gsap.to(
                ".p-index-recruit__intro--bg",
                {
                    opacity:0,
                    scrollTrigger: {
                        trigger: ".p-index-message__wrapper",
                        scroller: "[data-scroll-container]",
                        start: 'center+=20% bottom',  
                        end: 'center+=20% bottom-=20%', 
                        scrub:true,                 
                    }
                }
            );
        }
        const index_recruit = el.querySelector('#p-index-recruit')
        if (index_recruit){
            gsap.to(":root", {
                "--light-to-dark": "#0A131C",
                "--dark-to-light": "#F5ECE3",
                "--light-to-dark-rgb": "10, 19, 28",
                "--dark-to-light-rgb": "245, 236, 227",
                duration: .2,
                scrollTrigger: {
                    trigger: ".p-index-message__wrapper",
                    endTrigger:"#p-index-recruit",
                    scroller: "[data-scroll-container]",
                    start: "top bottom",
                    end: "bottom center+=20%",
                    toggleActions: "play reverse play reverse",   
                    // markers:true   
                },
            })
        }
        const aside_contact = el.querySelector('#l-aside-contact')
        if (aside_contact){
            gsap.to(
                '#l-aside-contact',
                {
                    borderRadius:"0",
                    ease: "power4.easeOut",
                    scrollTrigger: {
                        trigger:'#l-footer',
                        scroller: "[data-scroll-container]",
                        start: 'top+=30% bottom',
                        end: 'center center+=10%',
                        scrub:true,
                        
                    }
                }
            );
        }
        const side = el.querySelector("[data-stickey-side]")
        if(side){
            ScrollTrigger.matchMedia({
                "(min-width: 1024px)": function() {
                    ScrollTrigger.create({
                        trigger:el.querySelector("[data-stickey-container]") ,
                        scroller: "[data-scroll-container]",
                        start: 'top 200px',
                        end: 'bottom top+=' + (side.clientHeight + 200) + 'px',
                        pin: side,
                        // markers:true
                    });
                }
            })
        }

        const countUpTargets = document.querySelectorAll('.js-countUp-target');
        function runCountUpAnimation(target) {
            const elementDic = {
                from: target.dataset.from,
                to: target.dataset.to
            };
            const elementNum = { count: elementDic.from };
            gsap.to(elementNum, {
                count: elementDic.to,
                duration: 1,
                scrollTrigger: {
                    trigger: target,
                    scroller: "[data-scroll-container]",
                    start: 'top center+=30%',
                    // markers: true,
                },
                onUpdate: () => {
                    target.textContent = Math.floor(elementNum.count);
                }
            });
        }
        countUpTargets.forEach(target => {
            runCountUpAnimation(target);
        });

        const bars = document.querySelectorAll('.--bar');
        function runBarAnimation(bar) {
            const width = parseInt(bar.getAttribute('data-bar'));
            const tl = gsap.timeline({
                scrollTrigger: {
                trigger: bar.parentElement,
                scroller: "[data-scroll-container]",
                start: 'top center+=30%',
                // markers: true, 
            }
        })
        tl.to(bar, { 
            width: `calc(4px * ${width})`, duration: 1 });
        }
        bars.forEach(runBarAnimation);

    }

    switch_theme(container){

        if (container.querySelector('.darkmode')) {
            gsap.to(":root", {
                "--light-to-dark": "#0A131C",
                "--dark-to-light": "#F5ECE3",
                "--light-to-dark-rgb": "10, 19, 28",
                "--dark-to-light-rgb": "245, 236, 227",
                duration: 1,
            })
        }else{
            gsap.set(":root", {
                "--light-to-dark": "#F5ECE3",
                "--dark-to-light": "#0A131C",
                "--light-to-dark-rgb": "245, 236, 227",
                "--dark-to-light-rgb": "10, 19, 28",
                duration: 1,
            })
        }
    }
    refresh(){
        ScrollTrigger.addEventListener("refresh", () => {
            window.LocomotiveScroll.update()

        });   
    } 
    init(){
    }
}