
import barba from '@barba/core';
import * as THREE from 'three';

export default class{

	constructor(){
        this.mouseStalkerClass = "js-mouse-stalker";
        this.mouseCursorClass = "js-mouse-stalker__cursor";
        this.mouseFollowerClass = "js-mouse-stalker__follower";
        this.hoverClass = "is-hover";
        this.dragClass = "is-drag";


        this.reqestId = false
        this.scene
        this.camera
        this.renderer

        this.width = 420
        this.height = 280
        this.flag;
        this.flagColor = "#ffffff";
        this.flagTexture = null;
        this.sizeW = 65
        this.sizeH = 45
        this.segW = 30
        this.segH = 20

        this.angle = 0
        this.horizontal = 0.3
        this.vertical = 0.3
        this.swing = 0.1
        this.speed = 0.4

        const _ua = this._ua(window.navigator.userAgent.toLowerCase())
        
        barba.hooks.beforeOnce(() => {
            this.waveInit()
        })
        barba.hooks.afterOnce(() => {
            if(!_ua.Mobile&&!_ua.Tablet){
                this.mouseStalker()
            }
        });
        barba.hooks.after(() => {
            if(!_ua.Mobile&&!_ua.Tablet){
                this.mouseStalker()
            }
        });
    }
    
    mouseStalker (){        

        
        const stalker = document.querySelector('.' + this.mouseStalkerClass);
        const cursor = document.querySelector('.' + this.mouseCursorClass);
        const follower = document.querySelector('.' + this.mouseFollowerClass);
        const links = document.querySelectorAll("a,button,input[type='submit']");
        const stalkerLinks = document.querySelectorAll("[data-stalker-link]");
        const stalkerImageLinks = document.querySelectorAll("[data-stalker-image]");

        const cursorWidth = 20;
        let mouseX = 0;
        let mouseY = 0;
        if(stalker){
            document.addEventListener('mousemove', (e) => {
                stalker.style.opacity = 1;
                mouseX = e.clientX;
                mouseY = e.clientY;
                
                cursor.style.transform = "translate(" + parseInt(mouseX - (cursorWidth / 2)) + "px," + parseInt(mouseY - (cursorWidth / 2)) + "px)";
                follower.style.transform = "translate(" + parseInt(mouseX - (cursorWidth / 2)) + "px," + parseInt(mouseY - (cursorWidth / 2)) + "px)";
            });
        }
        const linkEnter = (el) => {
            if(stalker){
                el.addEventListener('mouseenter', (e) => {
                    if(!stalker.classList.contains(this.dragClass)){
                        stalker.classList.add(this.hoverClass);
                    }
                })
            }
        }
        const linkLeave = (el) => {
            if(stalker){
                el.addEventListener('mouseleave', (e) => {
                    stalker.classList.remove(this.hoverClass);
                });
            }
        }

        // const swiperEnter = (el) => {
        //     if(stalker){
        //         el.addEventListener('mouseenter', (e) => {
        //             stalker.classList.add(this.dragClass);
        //         })
        //     }
        // }
        // const swiperLeave = (el) => {
        //     if(stalker){
        //         el.addEventListener('mouseleave', (e) => {
        //             stalker.classList.remove(this.dragClass);
        //         });
        //     }
        // }

        const addClassLinkEnter = (el) => {
            if(stalker){
                el.addEventListener('mouseenter', (e) => {
                    const _class = el.getAttribute("data-class")
                    stalker.classList.add(_class);
                })
            }
        }
        const addClassLinkLeave = (el) => {
            if(stalker){
                el.addEventListener('mouseleave', (e) => {
                    const _class = el.getAttribute("data-class")
                    stalker.classList.remove(_class);
                });
            }
        }

        const imageLinkEnter = (el) => {
            if(cursor){
                el.addEventListener('mouseenter', (e) => {
                    stalker.classList.add("is-image-hover");
                    const src = el.getAttribute("data-stalker-image")
                    this.loadTexture(src)
                    this.update()
                })
            }
        }
        const imageLinkLeave = (el) => {
            if(cursor){
                el.addEventListener('mouseleave', (e) => {
                    stalker.classList.remove("is-image-hover");
                    this.cancel()
                });
            }
        }
        

        document.addEventListener('mouseleave', (e) => {
            if(stalker){
                stalker.style.opacity = 0;
            }
        });
        document.addEventListener('mouseenter', (e) => {
            if(stalker){
                stalker.style.opacity = 1;
            }
        });


        if(stalker){
            if(links.length > 0){
                links.forEach(element => {
                    linkEnter(element);
                    linkLeave(element);
                });
            }
           

            if(stalkerLinks.length > 0){
                stalkerLinks.forEach(element => {
                    addClassLinkEnter(element);
                    addClassLinkLeave(element);
                });
            }
            if(stalkerImageLinks.length > 0){
                stalkerImageLinks.forEach(element => {
                    imageLinkEnter(element);
                    imageLinkLeave(element);
                });
            }
        }
    }
    waveInit() {
        this.scene  = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(60,this.width/this.height,1,1000);
        this.camera.position.set(0,0,40);
        this.camera.lookAt(new THREE.Vector3(0,0.0));
        this.renderer = new THREE.WebGLRenderer({antialias:true, alpha: true });
        this.renderer.setSize(this.width,this.height);

        document.querySelector('.' + this.mouseFollowerClass).appendChild(this.renderer.domElement);

        this.light = new THREE.DirectionalLight("#FFFFFF");
        this.light.position.set(10,50,100);
        this.scene.add(this.light);
        this.ambientLight = new THREE.AmbientLight("#666666");
        this.scene.add(this.ambientLight);
        this.geometry = new THREE.CylinderGeometry(0.5,0.5,40,16,1);
        this.material = new THREE.MeshPhongMaterial({
            color:"#ffcc99",
            specular: "#000000",
            shininess: 0
        });

        this.geometry = new THREE.PlaneGeometry(this.sizeW,this.sizeH,this.segW,this.segH);
        this.material = new THREE.MeshLambertMaterial({
            color:this.flagColor,
            side:THREE.DoubleSide
        });
        this.flag = new THREE.Mesh(this.geometry,this.material);
        this.scene.add(this.flag);
        this.setMaterial();
        this.setCamera();
    }
    setMaterial(){
        this.flag.material = new THREE.MeshLambertMaterial({
            color: this.flagColor,
            map: this.flagTexture,
            side: THREE.DoubleSide
        });
    }
    loadTexture (src){
        this.loader = new THREE.TextureLoader();
        this.loader.load(src,texture => {
            texture.magFilter = THREE.LinearFilter;
            texture.minFilter = THREE.LinearFilter;
            this.flagTexture = texture;
            this.setMaterial();
        });
    }
    setCamera () {
        this.camera.position.x = 40 * Math.sin(this.angle * Math.PI/180);
        this.camera.position.z = 40 * Math.cos(this.angle * Math.PI/180);
        this.camera.lookAt(new THREE.Vector3(0,0,0));
    }
    update () {
    
        for (let y=0; y<this.segH+1; y++) {
            for (let x=0; x<this.segW+1; x++) {
                const index = x + y * (this.segW+1);
                const vertex = this.flag.geometry.vertices[index];
                const time = Date.now() * this.speed / 50;
                vertex.z = Math.sin(this.horizontal * x + this.vertical * y - time) * this.swing * x / 4;
            }
        }
        this.flag.geometry.verticesNeedUpdate = true;
        this.renderer.render(this.scene,this.camera);
        this.reqestId = requestAnimationFrame(this.update.bind(this));
    }
    // request(){
    // }
    cancel(){
        if(this.reqestId){
            cancelAnimationFrame(this.reqestId);
            this.reqestId = false
        }
    }
    
    _ua (u){
        return {
            Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) 
            || u.indexOf("ipad") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
            || u.indexOf("kindle") != -1
            || u.indexOf("silk") != -1
            || u.indexOf("playbook") != -1
            || u.indexOf('macintosh') > -1 && 'ontouchend' in document,
            Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
            || u.indexOf("iphone") != -1
            || u.indexOf("ipod") != -1
            || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
            || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
            || u.indexOf("blackberry") != -1
        }
    }
}